import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ShareButtons from '../components/share'
import "./five_ch-post.scss"
import switchCategory from '../utils/switchCategory.jsx';
import reactStringReplace from "react-string-replace";
import SEO from '../components/seo'
import AdSense from 'react-adsense'
import PrevNext from '../utils/prevNext.jsx'
import  { TwitterTweetEmbed } from 'react-twitter-embed';
import RegexSetting from '../utils/RegexFiles.jsx'
import Helmet from "react-helmet"
import GetRakutenAdsense from '../utils/getAffiliate'
import  ThemeContext  from '../components/context/ThemeContext';
import GoHome from '../utils/templatefooter.jsx' 
import RelatedPosts from '../components/relatedPosts'

function renderSwitch(item, depth, color, size, bold,index) {
  var decoration = color + " " + size + " " + bold

  if(index ==0 && item.length > 120)
  return(
    <blockquote className="font">
    <p className={decoration} style={{marginBottom:'20px'}}>
    {reactStringReplace(item, RegexSetting.normalUrl, (match, i) => {
      if( match.indexOf('://youtu') !== -1 ) {
        var movie_id = match.split("be/").slice(-1)[0];
        return(
          <p>
          <iframe src={"https://www.youtube.com/embed/" + movie_id}  style={{height: 'auto' , width:'90%',minHeight: '35vh', maxHeight:'400px', margin: '0 auto'}} />
        </p>
        )
      }
      if( match.indexOf('twitter.com') !== -1 && match.indexOf('twitter.com') !== -1 ) {
        var last = match.split("/status/").slice(-1)[0];
        var tweetid = last.split("?")[0]
        return(
          <TwitterTweetEmbed
            tweetId={tweetid}
          />
        )
      }
        else{
          return(
            <>
            <a href={match}>{match}</a>
            </>
          )
    }
  })}
    </p>
  </blockquote>
  )
  else if (depth < 1){
    return (
      <div className="font five_ch--text">
      <span className={decoration} style={{marginBottom:'20px'}}>
      {reactStringReplace(item, RegexSetting.normalUrl, (match, i) => {
        if( match.indexOf('://youtu') !== -1 ) {
          var movie_id = match.split("be/").slice(-1)[0];
          return(
            <p>
            <iframe src={"https://www.youtube.com/embed/" + movie_id}  style={{height: 'auto' , width:'90%',minHeight: '35vh', maxHeight:'400px', margin: '0 auto'}} />
          </p>
          )
        }
        if( match.indexOf('twitter.com') !== -1 && match.indexOf('twitter.com') !== -1 ) {
          var last = match.split("/status/").slice(-1)[0];
          var tweetid = last.split("?")[0]
          return(
            <TwitterTweetEmbed
              tweetId={tweetid}
            />
          )
        }
          else{
            return(
              <>
              <a href={match}>{match}</a>
              </>
            )
      }
    })}
      </span>
    </div>
    )
  }
  else {
    var anchorNum = item.indexOf('>>');
    var bar = item.substring(num).match(/\d+/);
    var num = String(bar).length
    if (anchorNum === 1) {
      return (
      <div className= "five_ch--text">
          <span className="five_ch--anchor">
            {item.substring(0, num + 3)}
          </span>
          <br/>
          <span className="font">
            <a className={decoration}>
            {reactStringReplace(item.substring(anchorNum + 2 + num), RegexSetting.normalUrl, (match, i) => {
              if( match.indexOf('://youtu') !== -1 ) {
                var movie_id = match.split("be/").slice(-1)[0];
                return(
                  <p>
                    <iframe src={"https://www.youtube.com/embed/" + movie_id}  style={{height: 'auto' , width:'90%',minHeight: '35vh', maxHeight:'400px', margin: '0 auto'}} />
                </p>
                )
              }
              if( match.indexOf('twitter.com') !== -1 && match.indexOf('twitter.com') !== -1 ) {
                var last = match.split("/status/").slice(-1)[0];
                var tweetid = last.split("?")[0]
                return(
                  <TwitterTweetEmbed
                    tweetId={tweetid}
                  />
                )
              }
                else{
                  return(
                    <>
                    <a href={match}>{match}</a>
                    </>
                  )
            }
            }
              )}
            </a>
          </span>
      </div>
      )
    }
    else return (
      <div className= "five_ch--text">
        <span >
          {item.substring(0, anchorNum)}
        </span>
        <span className="five_ch--anchor">
          {item.substring(anchorNum, anchorNum + num + 2)}
        </span>
        <br/>
        <span>
          <a className={decoration}>
          {reactStringReplace(item.substring(anchorNum + 2 + num), RegexSetting.normalUrl, (match, i) => {
            if( match.indexOf('://youtu') !== -1 ) {
              var movie_id = match.split("be/").slice(-1)[0];
              return(
                <p>
                  <iframe src={"https://www.youtube.com/embed/" + movie_id}  style={{height: 'auto' , width:'90%',minHeight: '35vh', maxHeight:'400px', margin: '0 auto'}} />
              </p>
              )
            }
              if( match.indexOf('twitter.com') !== -1 && match.indexOf('twitter.com') !== -1 ) {
                var last = match.split("/status/").slice(-1)[0];
                var tweetid = last.split("?")[0]
                return(
                  <TwitterTweetEmbed
                    tweetId={tweetid}
                  />
                )
              }
                else{
                  return(
                    <>
                    <a href={match}>{match}</a>
                    </>
                  )
            }
              }
              )}
          </a>
        </span>
      </div>
    )
  }
}

export default ({
  data: {
    allFiveChJson: { nodes }
  }
  , pageContext }) => {
    var icchiId

return (
  <>
  <Helmet
    bodyAttributes={{
        class: 'globalnody'
    }}
    />
        <ThemeContext.Consumer>
        {theme => (
          <Layout page="posts" tabnumber={switchCategory(nodes[0].genre[0].rough_genre)} themeContext={theme} template='five_ch'>
            <SEO title={nodes[0].title+ " | 話題に反応！"} description={nodes[0].title} image={nodes[0].top_image} />
            <main className="five_ch--main">
              <article>
              <header className="five_ch--header">
                <p className="five_ch--header__topimage">
                <img
                    src ={nodes[0].top_image}
                    className="five_ch--header__topimage__img"
                    />
                </p>
                <div className="five_ch--oneres">
                  <h1>{nodes[0].title}</h1>
                  <ShareButtons shareTitle={nodes[0].title} shareUrl={nodes[0].fields.slug}></ShareButtons>
                </div>
              </header>
              {
                Object.values(nodes[0].res).map(
                  (oneres,index) =>{
                    if(index==0) {icchiId = oneres.uid}
                    return(
                      <section key={index} className="five_ch--oneres">
                        <a className="five_ch--id">
                        {(
                          () => {
                            if (oneres.uid == icchiId) {
                              return ( <><a> {oneres.data_id + " "}</a> <a className='five_ch--oneres_time'>{ oneres.date + " "}</a> <a style={{backgroundColor:'#D9CA00'}}> {oneres.uid}</a></>);
                            } else {
                            return  ( <><a> {oneres.data_id + " "}</a> <a className='five_ch--oneres_time'>{ oneres.date + " "}</a> <a> {oneres.uid}</a></>);
                            }
                          })()
                        }
                        </a>
                        {renderSwitch(oneres.res_word, oneres.depth, oneres.font_color, oneres.font_size, oneres.font_bold, index)}
                        {
                          oneres.res_images.map((imgUrl,imageindex) => {
                            if ( imgUrl.includes('.mp4')) {
                              return(
                                <video controls style={{maxWidth: '90%', height: 'auto' , maxHeight:'400px', margin: '0 auto'}} >
                                  <source src={imgUrl} type="video/mp4" />
                                </video> 
                              )       
                              }
                            else{
                            return (
                              <div  key={imageindex}>
                                <img src={imgUrl} style={{maxWidth: '90%', height: 'auto' , maxHeight:'400px', margin: '0 auto'}}/>
                              </div>
                            )
                              }
                          }
                          )
                        }
                        {oneres.free_img == "" ? (<></>):(<img src={oneres.free_img} style={{maxWidth: '90%', height: 'auto' , maxHeight:'400px', margin: '0 auto'}}/>)}
                        {oneres.ad_word == "" ? (<></>):(<><GetRakutenAdsense adWord={oneres.ad_word}/></>)}
                      </section>
                    )
                  }
                )
              }
        {nodes[0].ad_words_all == "" ? (<></>):(<><GetRakutenAdsense adWord={nodes[0].ad_words_all }/></>)}
        <AdSense.Google client="ca-pub-9496221164080653" slot="1273467658" format="auto"/> 
        <p className="five_ch--quote">
          引用元:{nodes[0].url}
        </p>
        <RelatedPosts relatedPosts={pageContext.relatedPosts}/>
        <PrevNext prev={pageContext.prev} next={pageContext.next} />
        </article>
      </main>
    </Layout>
            )}
            </ThemeContext.Consumer>
    </>
  )
}

export const query = graphql`
query($slug: String!)  {
  allFiveChJson(filter: {fields: {slug: {eq: $slug}}}) {
          nodes {
          title
          date(formatString: "YYYY.MM.DD h:mm")
          categories
          genre{
            rough_genre
            strict_genre
          }
          fields {
              slug
            }
          res{
            uid
            data_id
            name
            res_word
            res_images
            date
            depth
            font_color
            font_size
            font_bold
            free_img
            ad_word
          }
          top_image
            url
            ad_words_all
        }
      }
  }
`
